import { render, staticRenderFns } from "./StatisticsRecipients.vue?vue&type=template&id=3b42ef80&scoped=true&"
import script from "./StatisticsRecipients.vue?vue&type=script&lang=js&"
export * from "./StatisticsRecipients.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsRecipients.vue?vue&type=style&index=0&id=3b42ef80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b42ef80",
  null
  
)

export default component.exports