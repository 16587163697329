<template>
    <div class="box">
        <h2>Statistiken</h2>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th>Statistik</th>
                        <th>Empfänger</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            KTV (alle iFrames)<br />
                            <span class="note">Immer am 01. eines Monats, 04:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.ktvBasicAll.recipient"
                                :tags="recipients.ktvBasicAll.recipients"
                                @tags-changed="newTags => newRecipients(recipients.ktvBasicAll, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            KTV (Maklerportal)<br />
                            <span class="note">Immer am 01. eines Monats, 04:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.ktvBasicMaklerportal.recipient"
                                :tags="recipients.ktvBasicMaklerportal.recipients"
                                @tags-changed="newTags => newRecipients(recipients.ktvBasicMaklerportal, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            WKV<br />
                            <span class="note">Immer am 01. eines Monats, 00:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.wkvEinzel.recipient"
                                :tags="recipients.wkvEinzel.recipients"
                                @tags-changed="newTags => newRecipients(recipients.wkvEinzel, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            WKV MeineCrefo<br />
                            <span class="note">Jeden Montag, 00:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.wkvMeineCrefo.recipient"
                                :tags="recipients.wkvMeineCrefo.recipients"
                                @tags-changed="newTags => newRecipients(recipients.wkvMeineCrefo, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            WKV MeineCrefo Importdatei<br />
                            <span class="note">Jeden Montag, 00:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.wkvMeineCrefoImport.recipient"
                                :tags="recipients.wkvMeineCrefoImport.recipients"
                                @tags-changed="newTags => newRecipients(recipients.wkvMeineCrefoImport, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            KreditConnect<br />
                            <span class="note">Immer am 01. eines Monats, 02:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.kreditconnect.recipient"
                                :tags="recipients.kreditconnect.recipients"
                                @tags-changed="newTags => newRecipients(recipients.kreditconnect, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            KTV-Geno/Exg Monatsstatistik<br />
                            <span class="note">Immer am 01. eines Monats, 03:00 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.ktvGenoExg.recipient"
                                :tags="recipients.ktvGenoExg.recipients"
                                @tags-changed="newTags => newRecipients(recipients.ktvGenoExg, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tellja Monatsstatistik<br />
                            <span class="note">Immer am 01. eines Monats, 02:30 Uhr</span>
                        </td>
                        <td>
                            <vue-tags-input
                                placeholder="Empfänger hinzufügen"
                                v-model="recipients.tellja.recipient"
                                :tags="recipients.tellja.recipients"
                                @tags-changed="newTags => newRecipients(recipients.tellja, newTags)"
                                :validation="tagValidation"
                                @before-adding-tag="beforeAddRecipient($event)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />

            <div class="button-row">
                <button @click="saveRecipients()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
    data() {
        return {
            recipients: {
                ktvBasicAll: {
                    recipients: [],
                    recipient: ""
                },
                ktvBasicMaklerportal: {
                    recipients: [],
                    recipient: ""
                },
                wkvEinzel: {
                    recipients: [],
                    recipient: ""
                },
                wkvMeineCrefo: {
                    recipients: [],
                    recipient: ""
                },
                wkvMeineCrefoImport: {
                    recipients: [],
                    recipient: ""
                },
                kreditconnect: {
                    recipients: [],
                    recipient: ""
                },
                ktvGenoExg: {
                    recipients: [],
                    recipient: ""
                },
                tellja: {
                    recipients: [],
                    recipient: ""
                }
            },
            tagValidation: [
                {
                    classes: "prefix",
                    rule: /^(To:|Cc:|Bcc:)/
                }
            ]
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/statistics/recipients").then(
                response => {
                    this.recipients.ktvBasicAll.recipients = this.unconvertRecipients(response.data.data.ktvBasicAll);
                    this.recipients.ktvBasicMaklerportal.recipients = this.unconvertRecipients(response.data.data.ktvBasicMaklerportal);
                    this.recipients.wkvEinzel.recipients = this.unconvertRecipients(response.data.data.wkvEinzel);
                    this.recipients.wkvMeineCrefo.recipients = this.unconvertRecipients(response.data.data.wkvMeineCrefo);
                    this.recipients.wkvMeineCrefoImport.recipients = this.unconvertRecipients(response.data.data.wkvMeineCrefoImport);
                    this.recipients.kreditconnect.recipients = this.unconvertRecipients(response.data.data.kreditconnect);
                    this.recipients.ktvGenoExg.recipients = this.unconvertRecipients(response.data.data.ktvGenoExg);
                    this.recipients.tellja.recipients = this.unconvertRecipients(response.data.data.tellja);
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Empfänger");
                }
            );
        },
        beforeAddRecipient(data) {
            if (data.tag.tiClasses.indexOf("ti-invalid") > -1) {
                // Invalid tag, do not add
                this.$snotify.error("Ungültiger Empfänger. Prefix 'To:', 'Cc:' oder 'Bcc:' nötig");
            } else {
                data.addTag();
                return true;
            }

            return false;
        },
        newRecipients(recipients, tags) {
            recipients.recipients = tags;
            recipients.recipient = "";
        },
        convertRecipients(tags) {
            var result = {
                to: [],
                cc: [],
                bcc: []
            };
            for (var t = 0; t < tags.length; t++) {
                var recipient = tags[t].text;
                if (typeof recipient === "undefined") {
                    continue;
                }
                if (recipient.indexOf("To:") === 0) {
                    result.to.push(recipient.substring(3));
                } else if (recipient.indexOf("Cc:") === 0) {
                    result.cc.push(recipient.substring(3));
                } else if (recipient.indexOf("Bcc:") === 0) {
                    result.bcc.push(recipient.substring(4));
                }
            }

            return result;
        },
        unconvertRecipients(recipients) {
            var tags = [];
            if (recipients.to !== null) {
                for (let r = 0; r < recipients.to.length; r++) {
                    tags.push({ text: "To:" + recipients.to[r] });
                }
            }
            if (recipients.cc !== null) {
                for (let r = 0; r < recipients.cc.length; r++) {
                    tags.push({ text: "Cc:" + recipients.cc[r] });
                }
            }
            if (recipients.bcc !== null) {
                for (let r = 0; r < recipients.bcc.length; r++) {
                    tags.push({ text: "Bcc:" + recipients.bcc[r] });
                }
            }

            return tags;
        },
        saveRecipients() {
            var recipients = {
                ktvBasicAll: this.convertRecipients(this.recipients.ktvBasicAll.recipients),
                ktvBasicMaklerportal: this.convertRecipients(this.recipients.ktvBasicMaklerportal.recipients),
                wkvEinzel: this.convertRecipients(this.recipients.wkvEinzel.recipients),
                wkvMeineCrefo: this.convertRecipients(this.recipients.wkvMeineCrefo.recipients),
                wkvMeineCrefoImport: this.convertRecipients(this.recipients.wkvMeineCrefoImport.recipients),
                kreditconnect: this.convertRecipients(this.recipients.kreditconnect.recipients),
                ktvGenoExg: this.convertRecipients(this.recipients.ktvGenoExg.recipients),
                tellja: this.convertRecipients(this.recipients.tellja.recipients)
            };
            this.$api.post("/statistics/recipients", recipients).then(
                () => {
                    this.$snotify.success("Empfänger gespeichert");
                },
                () => {
                    this.$snotify.error("Save failed");
                }
            );
        }
    },
    components: {
        VueTagsInput
    }
};
</script>

<style lang="scss" scoped>
span.note {
    font-size: 10px;
}
</style>
